import React, { useEffect } from "react";
import { useState } from "react";
import StepperStore from "../../../../../LocalForage/StepperStore";
import Functions from "../../../../../Helpers/Functions";
import moment from "moment";
import PunctualCourseRepository from "../../../../../Repositories/PunctualCourseRepository";
import { APIErrorEnum } from "../../../../../Objects/enums/APIErrorEnum";
import { PunctualCourseDashboard } from "../../../../../Objects/models/PunctualCourseDashboard";
import CourseProposal from "../../../../../Objects/models/CourseProposal";
import TeachrRepository from "../../../../../Repositories/TeachrRepository";
import CourseProposalRepository from "../../../../../Repositories/CourseProposal";
import ChildRepository from "../../../../../Repositories/ChildRepository";
import AddressRepository from "../../../../../Repositories/AddressRepository";
import Teachr_Repository from "../../../../../Repositories/Teachr_Repository";
import { auth } from "../../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
// import { SubscriptionCourseDashboard } from "../../../../../Objects/models/SubscriptionCourseDashboard";
// import SubscriptionCourseProposalRepository from "../../../../../Repositories/SubscriptionCourseProposalRepositorys";
// import { SubscriptionCourseProposal } from "../../../../../Objects/models/SubscriptionCourseProposal";
// import SubscriptionCourseRepositorys from "../../../../../Repositories/SubscriptionCourseRepository";
// import TeachrPutTva from "../../../../../Objects/models/TeachrPutTva";
import Swal from "sweetalert2";
import Teachr from "../../../../../Objects/models/Teachr";
import { translateDay } from "../UtilsDashboard/FunctionsDashbord";

// const days = [
//   {
//     fr: 'Lundi',
//     en: 'monday'
//   },
//   {
//     fr: 'Mardi',
//     en: 'tuesday'
//   },
//   {
//     fr: 'Mercredi',
//     en: 'wednesday'
//   },
//   {
//     fr: 'Jeudi',
//     en: 'thursday'
//   },
//   {
//     fr: 'Vendredi',
//     en: 'friday'
//   },
//   {
//     fr: 'Samedi',
//     en: 'saturday'
//   },
//   {
//     fr: 'Dimanche',
//     en: 'sunday'
//   }
// ]
// const translateDay = (day: string) => {
//   const translate = days.filter((item: any) => item.en.toLowerCase() === day.toLowerCase())
//   if (translate.length >= 0) {
//     return translate[0].fr
//   }
//   return ''

// }

const StepConfirmation = (props: any) => {
  const { handleChangeStep } = props
  let timerInterval: any;
  const [user] = useAuthState(auth);
  const [datas, setDatas] = useState<any>({});
  const [dataPast, setDataPast] = useState<any>({});
  const [load, setLoad] = useState(false);
  const [datasPunctual, setDatasPunctual] = useState<any>({});
  const [datasPunctualMonth, setDatasPunctualMonth] = useState<any>({});
  const [child, setChild] = React.useState<any>();
  const [prices, setPrices] = useState<any>();
  const [cours, setCours] = useState<any>();
  const [customFees2, setCustomFees2] = useState<any>();
  const [teachr, setTeachr] = useState<Teachr>();



  // useEffect(() => {
  //   Teachr_Repository.getCurrentTeachr().then((res: any) => {
  //     if (res.id) {
  //       PunctualCourseRepository.getPunctualCourseByTeachr(res.id);
  //     }
  //   })
  // }, [])
  useEffect(() => {
    StepperStore.removeItem("activeStepundefined")
  }, [])

  useEffect(() => {
    Teachr_Repository.getCurrentTeachr().then((res: Teachr) => {
      setTeachr(res)
    })
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const cours: any = await StepperStore.getItem("cours" + user?.uid);
        const location: any = await StepperStore.getItem("location" + user?.uid);
        const price: any = await StepperStore.getItem("price" + user?.uid);
        const regulier: any = await StepperStore.getItem("regulier" + user?.uid);
        const student: any = await StepperStore.getItem("student" + user?.uid);
        const dataPast: any = await StepperStore.getItem("dataPast" + user?.uid);
        const punctual: any = await StepperStore.getItem("punctual" + user?.uid);
        const punctualMonth: any = await StepperStore.getItem("punctualMonth" + user?.uid);
        const customPunctual = punctual ? punctual : punctualMonth;
        const teachrs: any = await TeachrRepository.getCurrentTeachr();

        setCours(cours.cours)

        if (customPunctual) {
          if (customPunctual.customFees) {
            setCustomFees2(customPunctual.customFees);
            if (customPunctual.customFees.is_teachr_contact) {
              if (price) {
                setPrices((price.price * 2 * (1 - teachrs?.defaultFees)).toString())
              }
            } else {
              setPrices(customPunctual.customFees.price_per_hour.toString());
            }
          } else {
            if (price) {
              setPrices((price.price * 2 * (1 - teachrs?.defaultFees)).toString())
            }
          }
        }


        if (location !== null && student != null && price !== null && regulier !== null) {
          setDatas({
            cours: cours.cours,
            student: student.value,
            matiere: location.matiere.fr_FR,
            price: price.price,
            priceRenum: (price.price * 2 * (1 - teachrs?.defaultFees)).toString(),
            dateSub: regulier.date,
            days: regulier.day,
            startTime: regulier.startTime,
            day: regulier.day,
            hour: regulier.rangeNumber * 2,
            defaultFees: teachrs?.defaultFees,
          });
        }
        if (dataPast !== null) {
          setDataPast({
            cours: cours.cours,
            student: student.value,
            matiere: dataPast.matiere.fr_FR,
            price: price.price,
            priceRenum: (price.price * 2 * (1 - teachrs?.defaultFees)).toString(),
            hour: dataPast.duration * 2,
            defaultFees: teachrs?.defaultFees,
          });
        }
        if (location !== null && student != null && punctual !== null) {
          if (punctual.customFees) {
            setDatasPunctual({
              cours,
              student: student.value,
              matiere: location.matiere.fr_FR,
              price: punctual.customFees.is_teachr_contact ? price?.price : punctual?.customFees?.price_per_hour.toString(),
              priceRenum: punctual.customFees.is_teachr_contact ? (price.price * 2 * (1 - teachrs?.defaultFees)).toString() : punctual.customFees.price_per_hour.toString(),
              datePunctual: punctual.date,
              endTime: punctual.endTime,
              startTime: punctual.startTime,
              hour: punctual.rangeNumber * 2,
              customFees: punctual.customFees,
              defaultFees: teachrs?.defaultFees,
              punctual
            });
          } else {
            if (price !== null) {
              setDatasPunctual({
                cours,
                student: student.value,
                matiere: location.matiere.fr_FR,
                price: price.price,
                priceRenum: (price.price * 2 * (1 - teachrs?.defaultFees)).toString(),
                datePunctual: punctual.date,
                endTime: punctual.endTime,
                startTime: punctual.startTime,
                hour: punctual.rangeNumber * 2,
                defaultFees: teachrs?.defaultFees,
                punctual
              });

            }
          }
        }
        if (location !== null && student != null && punctualMonth !== null) {
          if (punctualMonth.customFees) {
            setDatasPunctualMonth({
              cours,
              student: student.value,
              matiere: location.matiere.fr_FR,
              subject: location.matiere,
              price: punctualMonth.customFees.is_teachr_contact ? price?.price : punctualMonth?.customFees?.price_per_hour.toString(),
              priceRenum: punctualMonth.customFees.is_teachr_contact ? (price.price * 2 * (1 - teachrs?.defaultFees)).toString() : punctualMonth.customFees.price_per_hour.toString(),
              endTime: punctualMonth.endDateTime,
              startTime: punctualMonth.startDateTime,
              duration: punctualMonth.duration * 2,
              customFees: punctualMonth.customFees,
              defaultFees: teachrs?.defaultFees,
              notes: punctualMonth.notes,
              punctualMonth
            });
          } else {
            if (price !== null) {
              setDatasPunctualMonth({
                cours,
                student: student.value,
                matiere: location.matiere.fr_FR,
                subject: location.matiere,
                price: price.price,
                priceRenum: (price.price * 2 * (1 - teachrs?.defaultFees)).toString(),
                endTime: punctualMonth.endDateTime,
                startTime: punctualMonth.startDateTime,
                duration: punctualMonth.duration * 2,
                defaultFees: teachrs?.defaultFees,
                notes: punctualMonth.notes,
                punctualMonth
              });
            }
          }
        }
      } catch (error) {
        console.warn(error);
      }
    })();

  }, [user?.uid])
  useEffect(() => {
    StepperStore.getItem("student" + user?.uid).then((student: any) => {
      if (student !== null) {
        const id = parseInt(student?.value);
        ChildRepository.fetchChildById(id)
          .then((res) => {
            setChild(res);
          })
          .catch((re) => console.warn(re));
      }
    });
  }, [user?.uid])
  // const createSubscriptionCourse = async () => {
  //   setLoad(true)
  //   try {
  //     const location: any = await StepperStore.getItem("location" + user?.uid);
  //     const regulier: any = await StepperStore.getItem("regulier" + user?.uid);
  //     const teachrs = await TeachrRepository.getCurrentTeachr();
  //     const forChild_id = child.id;
  //     const isRemote = false
  //     const extraInformation = ""
  //     const daysOfTheWeek = regulier.day
  //     const subjectAsked_id = location.matiere.id;
  //     let startTime = moment(regulier.startTime).format("HH:mm");
  //     let startDate = moment(regulier.date).format("Y-MM-DD");
  //     const durationPerDay = regulier.rangeNumber * 2
  //     const paymentMean = "CARD";
  //     const aiciActivated = true
  //     const isDirectCourse = true
  //     const teachrPrice = prices
  //     const dataCourse = [
  //       {
  //         "startTime": startTime,
  //         "teachrPrice": teachrPrice,
  //         "durationPerDay": durationPerDay,
  //       }
  //     ]
  //     AddressRepository.getClientAddresses(child.parent["@id"].substr(9)).then((address) => {
  //       setLoad(true)
  //       address.map((address_id) => {
  //         if (address_id.isDefault === true) {
  //           if (address_id.id) {
  //             const subscriptionCourse = new SubscriptionCourseDashboard(
  //               forChild_id,
  //               isRemote,
  //               address_id.id,
  //               subjectAsked_id,
  //               daysOfTheWeek,
  //               startDate,
  //               startTime,
  //               durationPerDay,
  //               paymentMean,
  //               extraInformation,
  //               aiciActivated,
  //               isDirectCourse,
  //               teachrs.id,
  //               dataCourse
  //             )
  //             if (teachrs.id) {
  //               SubscriptionCourseRepositorys.createSubscriptionCourseByTeachr(subscriptionCourse, teachrs.id).then((course: any) => {
  //                 setLoad(true)
  //                 if (teachrs.id && course.id) {
  //                   const teachrPrice = prices;
  //                   //to-do
  //                   const proposal = new SubscriptionCourseProposal(course.id, teachrs.id, teachrPrice)
  //                   SubscriptionCourseProposalRepository.createSubscriptionCourseProposalByTeachr(proposal, teachrs.id, course.id).then((proposalCourse: any) => {
  //                     setLoad(true)
  //                     if (teachrs.id && proposalCourse.id) {
  //                       SubscriptionCourseProposalRepository.tryAcceptSubCourseProposalByTeachr(teachrs.id, proposalCourse.id).then((accept) => {
  //                         setLoad(true)
  //                         handleChangeStep(8);

  //                       }).catch((err) => {
  //                         console.warn(err);
  //                         setLoad(false)
  //                       })
  //                     }
  //                   }).catch((err) => {
  //                     console.warn(err);
  //                     setLoad(false)
  //                   })
  //                 }
  //               }).catch((err) => {
  //                 console.warn(err);
  //                 setLoad(false)
  //               })


  //             }

  //           }
  //         }
  //       })

  //     }).catch((error) => {
  //       setLoad(false)
  //       console.warn(error);
  //     })
  //   } catch (error) {
  //     setLoad(false)
  //     console.warn(error);
  //   }
  // }
  const createPonctualCourse = async () => {
    setLoad(true)
    try {
      const location: any = await StepperStore.getItem("location" + user?.uid);
      const punctual: any = await StepperStore.getItem("punctual" + user?.uid);
      const teachrs: any = await TeachrRepository.getCurrentTeachr();
      const paymentMean = "CARD";
      const duration = (punctual.rangeNumber * 2).toString();
      const subjectAsked_id = location.matiere.id;
      const forChild_id = child.id;
      const isFiscalReductionEnabled = false;
      const chapter = "";
      const teachr_id = teachrs.id;
      const isRemote = false;
      const isCourseInTheHour = false;
      const isDirectCourse = true;
      let startTime = moment(punctual.startTime);
      let endTime = moment(punctual.endTime);
      let startDate = moment(punctual.date);
      let startDateTime = startDate
        .set("hour", startTime.hours())
        .set("minutes", startTime.minutes())
        .toDate();
      let endDateTime = startDate
        .set("hour", endTime.hours())
        .set("minutes", endTime.minutes())
        .toDate();
      const chapterExtraInformation = "";
      const globalExtraInformation = "";

      // if (startTime > moment()) {
      AddressRepository.getClientAddresses(child.parent["@id"].substr(9)).then(
        (addr) => {
          setLoad(true)
          addr.map((add) => {
            if (add.isDefault === true) {
              if (add.id) {
                const course = new PunctualCourseDashboard(
                  forChild_id,
                  chapter,
                  add.id,
                  subjectAsked_id,
                  startDateTime,
                  endDateTime,
                  isRemote,
                  teachr_id,
                  isCourseInTheHour,
                  duration,
                  isDirectCourse,
                  isFiscalReductionEnabled,
                  paymentMean,
                  true,
                  chapterExtraInformation,
                  globalExtraInformation
                );
                course.isPunctualMonth = false
                course.isPassedCourse = false;

                PunctualCourseRepository.createPunctualCourseByTeacher(course)
                  .then((res) => {
                    setLoad(true)
                    if (prices) {
                      const punctual: any = `/punctual_courses/${res.id}`;
                      // const teachr = teachr?.["@id"];
                      const teachrPrice = prices;
                      const description = null;
                      if (teachr?.["@id"]) {
                        const proposal = new CourseProposal(
                          teachr?.["@id"],
                          punctual,
                          startDateTime,
                          endDateTime,
                          teachrPrice,
                          description
                        );
                        proposal.isFromTeachr = true;
                        if (res) {
                          CourseProposalRepository.createCourseProposal(proposal).then((res: any) => {
                            setLoad(true)
                            PunctualCourseRepository.tryAcceptPunctualCourseByTeachr(
                              res.punctualCourse.forChild.parent.id,
                              res.id
                            )
                              .then((res) => {
                                if (teachr.id) {
                                  if (teachr.isFirstCourse) {
                                    PunctualCourseRepository.firstCourseDashboard(teachr.id)
                                  }
                                  setLoad(true)
                                  handleChangeStep(8);
                                }
                              })
                              .catch((err) => {
                                console.warn(err)
                                setLoad(false)
                              });
                          }).catch((error) => {
                            setLoad(false)

                            if (error._description === "The Teach'r has already an accepted course for this timeslot.") {
                              Swal.fire({
                                icon: "warning",
                                title: "Attention !",
                                text: "Vous avez déjà un cours prévu pour ce créneau horaire.",
                                timer: 5000,
                                willClose: () => {
                                  clearInterval(timerInterval);
                                },
                              })
                            } else
                              error._arrayInvalidAttributes.map((err: any) => {
                                if (err.description === "This value should be between 11 and 250.") {
                                  Swal.fire({
                                    icon: "warning",
                                    title: "Attention !",
                                    text: "Le prix par heure doit se situer dans une fourchette allant de 11 à 250 euros.",
                                    timer: 5000,
                                    willClose: () => {
                                      clearInterval(timerInterval);
                                    },
                                  })
                                }
                                if (err.description === "This value should be between 9 and 23.") {
                                  Swal.fire({
                                    icon: "warning",
                                    title: "Attention !",
                                    text: "Le prix par heure doit se situer dans une fourchette allant de 9 à 23 euros.",
                                    timer: 5000,
                                    willClose: () => {
                                      clearInterval(timerInterval);
                                    },
                                  })
                                }
                                if (err.description === "The end datetime must be within the next month.") {
                                  Swal.fire({
                                    icon: "warning",
                                    title: "Attention !",
                                    text: "La déclaration des cours ne doit pas excéder une durée d'un mois.",
                                    timer: 5000,
                                    willClose: () => {
                                      clearInterval(timerInterval);
                                    },
                                  })
                                }
                                if (err.description === "The start datetime must be in the future.") {
                                  Swal.fire({
                                    icon: "warning",
                                    title: "Attention !",
                                    text: "L'heure de début du cours est déjà passée. Merci de bien vouloir la modifier.",
                                    timer: 5000,
                                    willClose: () => {
                                      clearInterval(timerInterval);
                                    },
                                  })
                                }
                                if (err.description === "The end datetime must be in the future.") {
                                  Swal.fire({
                                    icon: "warning",
                                    title: "Attention !",
                                    text: "L'heure de début du cours est déjà passée. Merci de bien vouloir la modifier.",
                                    timer: 5000,
                                    willClose: () => {
                                      clearInterval(timerInterval);
                                    },
                                  })
                                }

                              })
                          }
                          )
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    console.warn(error)
                    setLoad(false)
                    switch (error.description) {
                      case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "La durée du cours est supérieure à la plage horaire définie.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "Vous devez définir au moins une adresse dans votre compte.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.COURSE_PROPOSAL_TEACHR_HAS_ALREADY_A_COURSE_AT_THIS_TIME:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "Vous avez déjà un cours prévu à cette date",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "Vous avez déjà trop de demandes de cours en attente.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "La durée du cours ne peut pas dépasser les 12 heures.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "La durée du cours ne peut pas être inférieure à 1 heure.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "Il ne s'agit pas d'un cours dans l'heure.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "Il s'agit d'un cours dans l'heure.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;
                      case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
                        Swal.fire({
                          icon: "warning",
                          title: "Attention !",
                          text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
                          timer: 2000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        break;

                      default:
                        break;
                    }
                  });
              }
            }
          });
        }
      ).catch((err) => {
        console.warn(err)
        setLoad(false)
      }
      )
      // }else{
      //     setLoad(false)
      //     Swal.fire({
      //         icon: "warning",
      //         title: "Attention !",
      //         text: "L'heure du debut de ce cours est déjà passée, veuillez modifier l'heure du début",
      //         timer: 4000,
      //         willClose: () => {
      //             clearInterval(timerInterval);
      //             setLoad(false)
      //         },
      //     });
      //     setLoad(false)
      // }
    } catch (error: any) {
      setLoad(false)
      console.warn(error);

    }
  };
  const createPonctualCourseMonth = async () => {
    setLoad(true)
    try {
      if (teachr !== undefined) {
        const paymentMean = "CARD";
        const duration = (datasPunctualMonth?.duration).toString();
        const subjectAsked_id = datasPunctualMonth?.subject.id;
        const forChild_id = child?.id;
        const isFiscalReductionEnabled = false;
        const chapter = datasPunctualMonth?.notes;
        const teachr_id = teachr?.id;
        const isRemote = false;
        const isCourseInTheHour = false;
        const isDirectCourse = true;
        let startDateTime = datasPunctualMonth?.startTime;
        let endDateTime = datasPunctualMonth?.endTime;
        const globalExtraInformation = datasPunctualMonth?.notes;

        AddressRepository.getClientAddresses(child.parent["@id"].substr(9)).then(
          (addr) => {
            setLoad(true)
            addr.map((add) => {
              if (add.isDefault === true) {
                if (add.id) {
                  const course = new PunctualCourseDashboard(
                    forChild_id,
                    chapter,
                    add.id,
                    subjectAsked_id,
                    startDateTime,
                    endDateTime,
                    isRemote,
                    teachr_id,
                    isCourseInTheHour,
                    duration,
                    isDirectCourse,
                    isFiscalReductionEnabled,
                    paymentMean,
                    true,
                    "",
                    globalExtraInformation
                  );
                  course.isPunctualMonth = true;
                  course.isPassedCourse = false;

                  PunctualCourseRepository.createPunctualCourseByTeacher(course)
                    .then((res) => {
                      setLoad(true)
                      if (prices && teachr["@id"]) {
                        const punctual: any = `/punctual_courses/${res.id}`;
                        const teachrPrice = prices;
                        const description = null;



                        const proposal = new CourseProposal(
                          teachr["@id"],
                          punctual,
                          startDateTime,
                          endDateTime,
                          teachrPrice,
                          description
                        );
                        proposal.isFromTeachr = true;
                        if (res) {
                          CourseProposalRepository.createCourseProposal(proposal)
                            .then((res: any) => {
                              setLoad(true);
                              PunctualCourseRepository.tryAcceptPunctualCourseByTeachr(
                                res.punctualCourse.forChild.parent.id,
                                res.id
                              )
                                .then((res) => {
                                  setLoad(true);
                                  handleChangeStep(8);
                                })
                                .catch((err) => {
                                  console.warn(err);

                                  setLoad(false);
                                });
                            })
                            .catch((error) => {
                              setLoad(false);
                              if (
                                error._description ===
                                "The Teach'r has already an accepted course for this timeslot."
                              ) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "Attention !",
                                  text: "Vous avez déjà un cours prévu pour ce créneau horaire.",
                                  timer: 5000,
                                  willClose: () => {
                                    clearInterval(timerInterval);
                                  },
                                });
                              } else if (
                                error._description ===
                                "The duration proposed by the Teach'r doesn't correspond to the duration wanted by the Client."
                              ) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "Attention !",
                                  text: "La durée suggérée n'est pas adéquate, merci de changer l'heure de début.",
                                  timer: 5000,
                                  willClose: () => {
                                    clearInterval(timerInterval);
                                  },
                                });
                              } if (
                                error._arrayInvalidAttributes[0].description ===
                                "This value should be between 11 and 250."
                              ) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "Attention !",
                                  text: "Le prix par heure doit se situer dans une fourchette allant de 11 à 250 euros.",
                                  timer: 5000,
                                  willClose: () => {
                                    clearInterval(timerInterval);
                                  },
                                });

                              } else
                                if (
                                  error._arrayInvalidAttributes[0].description ===
                                  "This value should be between 9 and 23."
                                ) {
                                  Swal.fire({
                                    icon: "warning",
                                    title: "Attention !",
                                    text: "Le prix par heure doit se situer dans une fourchette allant de 9 à 23 euros.",
                                    timer: 5000,
                                    willClose: () => {
                                      clearInterval(timerInterval);
                                    },
                                  });
                                }
                            });
                        }
                      }
                    })
                    .catch((error) => {
                      console.warn(error)
                      setLoad(false)
                      switch (error.description) {
                        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "La durée du cours est supérieure à la plage horaire définie.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "Vous devez définir au moins une adresse dans votre compte.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.COURSE_PROPOSAL_TEACHR_HAS_ALREADY_A_COURSE_AT_THIS_TIME:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "Vous avez déjà un cours prévu à cette date",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "Vous avez déjà trop de demandes de cours en attente.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "La durée du cours ne peut pas dépasser les 12 heures.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "Il ne s'agit pas d'un cours dans l'heure.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "Il s'agit d'un cours dans l'heure.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;
                        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
                          Swal.fire({
                            icon: "warning",
                            title: "Attention !",
                            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
                            timer: 2000,
                            willClose: () => {
                              clearInterval(timerInterval);
                            },
                          });
                          break;

                        default:
                          break;
                      }
                    });
                }
              }
            });
          }
        ).catch((err) => {
          console.warn(err)
          setLoad(false)
        }
        )
      }
    } catch (error: any) {
      setLoad(false)
      console.warn(error);

    }

  };
  const handleSubmit = () => {
    StepperStore.setItem("type" + user?.uid, {
      cours: datas.cours ? datas.cours : datasPunctualMonth.cours ? datasPunctualMonth.cours : datasPunctual.cours,
    });
    switch (cours) {
      // case "regulier":
      //   createSubscriptionCourse();
      //   break;
      case "punctual":
        createPonctualCourse();
        break;
      case "punctualMonth":
        createPonctualCourseMonth();
        break;
      default:
        break;
    }
  };
  const handleBack = () => {
    if (customFees2) {
      if (customFees2?.is_teachr_contact) {
        handleChangeStep(6);
      } else {
        if(cours === "punctualMonth") {
          handleChangeStep(5);
        } else {
          handleChangeStep(4);
        }
      }
    } else {
      handleChangeStep(6);
    }
  }

  return (
    <div className="overflow-y-auto list-teacher relative h-[100%]  bg-white rounded-lg shadow-dashboard-setup-intro-form">
      <div className="relative h-[100%]">
        <div className="h-[90%] /h-[100%] md:p-8 p-6 ">
          <h2 className="font-extrabold /lg:text-3xl md:text-2xl text-xl text-dark_blue">
            Récapitulatif :{" "}
          </h2>
          <div className="space-y-1 md:translate-y-6">
            <span className="/text-sm font-bold text-[#44d4d4d] lg:text-xl md:text-base text-base">
              Avant de confirmer votre déclaration, veuillez vérifier
              attentivement les informations fournies.
            </span>
            <div className="space-x-2 /md:translate-y-2 md:flex">
              <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                Matière :{" "}
                <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                  {cours === "regulier"
                    ? datas.matiere
                    : cours === "punctualMonth"
                      ? datasPunctualMonth?.matiere
                      : datasPunctual?.matiere}
                </strong>
              </p>
            </div>
            <div className="space-x-2 md:flex">
              <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                Durée :{" "}
                <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                  {cours === "regulier"
                    ? Functions.renderDuration(datas?.hour)
                    : cours === "punctualMonth"
                      ? Functions.renderDuration(datasPunctualMonth?.duration)
                      : Functions.renderDuration(datasPunctual.hour)}
                </strong>{" "}
              </p>
            </div>
            <div className="space-x-2 md:flex">
              <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                Cours :{" "}
                <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                  À domicile
                </strong>{" "}
              </p>
            </div>
            <div className="space-x-2 md:flex">
              <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                Type de cours :{" "}
                <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                  {cours === "regulier"
                    ? "Régulier"
                    : cours === "punctualMonth"
                      ? "Plusieurs cours"
                      : "Ponctuel"}
                </strong>{" "}
              </p>
            </div>
            {cours === "punctualMonth" ? (
              <div className="space-x-2 md:flex">
                <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                  Période des cours :{" "}
                  <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                    {moment(datasPunctualMonth.startTime)
                      .format("dddd DD MMMM YYYY")
                      .charAt(0)
                      .toUpperCase() +
                      moment(datasPunctualMonth.startTime)
                        .format("dddd DD MMMM YYYY")
                        .slice(1) +
                      " au " +
                      moment(datasPunctualMonth.endTime)
                        .format("dddd DD MMMM YYYY")
                        .charAt(0)
                        .toUpperCase() +
                      moment(datasPunctualMonth.endTime)
                        .format("dddd DD MMMM YYYY")
                        .slice(1)}
                  </strong>{" "}
                </p>
              </div>
            ) : (
              <div className="space-x-2 md:flex">
                <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                  Date et Heure :{" "}
                  <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                    {cours === "regulier"
                      ? moment(datas.dateSub).format(" dddd DD MMMM YYYY") +
                      " à " +
                      Functions.renderTime(datas.startTime)
                      : moment(datasPunctual.datePunctual).format(
                        " dddd DD MMMM YYYY"
                      ) +
                      " à " +
                      Functions.renderTime(datasPunctual.startTime)}
                  </strong>{" "}
                </p>
              </div>
            )}
            {cours === "regulier" && (
              <div className="space-x-2 md:flex">
                <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                  Tous les :{" "}
                  {datas?.days?.map((day: string, key: any) => (
                    <span
                      key={key}
                      className="after:content-[','] last:after:content-[''] lg:text-lg md:text-base text-sm font-normal text-dark_blue"
                    >
                      &#160;{translateDay(day)}
                    </span>
                  ))}
                </p>
              </div>
            )}
            <div className="space-x-2 md:flex">
              <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                Pour :{" "}
                <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                  {/* {datas.cours === "regulier" ? datas.student : datasPunctual.student} */}
                  {child === undefined ? "chargement..." : child.firstName}
                </strong>{" "}
              </p>
            </div>
            {customFees2 === undefined ? (
              <div className="space-x-2 md:flex">
                <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                  Prix total payé{" "}
                  {cours === "punctualMonth" ? "pour la" : "par"} séance :{" "}
                  <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                    {cours === "regulier"
                      ? ((datas.price * datas.hour) / 2).toFixed(2)
                      : cours === "punctualMonth"
                        ? (
                          (datasPunctualMonth.price *
                            datasPunctualMonth.duration) /
                          2
                        ).toFixed(2)
                        : (
                          (datasPunctual.price * datasPunctual.hour) /
                          2
                        ).toFixed(2)}
                    €
                  </strong>{" "}
                </p>
              </div>
            ) : !customFees2?.is_teachr_contact ? (
              <div className="space-x-2 md:flex">
                <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                  Rémunération horaire :{" "}
                  <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                    {cours === "regulier"
                      ? datas.price
                      : cours === "punctualMonth"
                        ? datasPunctualMonth.price
                        : datasPunctual.price}
                    €
                  </strong>{" "}
                </p>
              </div>
            ) : (
              <div className="space-x-2 md:flex">
                <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                  Prix total payé{" "}
                  {cours === "punctualMonth" ? "pour le mois" : "par séance"} :{" "}
                  <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                    {cours === "regulier"
                      ? ((datas.price * datas.hour) / 2).toFixed(2)
                      : cours === "punctualMonth"
                        ? (
                          (datasPunctualMonth.price *
                            datasPunctualMonth.duration) /
                          2
                        ).toFixed(2)
                        : (
                          (datasPunctual.price * datasPunctual.hour) /
                          2
                        ).toFixed(2)}
                    €
                  </strong>{" "}
                </p>
              </div>
            )}
            {datas.cours === "regulier" ? (
              datas?.days?.length === 1 ? (
                ""
              ) : (
                <div className="space-x-2 md:flex">
                  <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                    Prix total payé par semaine :{" "}
                    <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                      {datas.cours === "regulier"
                        ? (
                          ((datas.price * datas.hour) / 2) *
                          datas?.days?.length
                        ).toFixed(2)
                        : (
                          ((datasPunctual.price * datasPunctual.hour) / 2) *
                          datas?.days?.length
                        ).toFixed(2)}{" "}
                      €
                    </strong>{" "}
                  </p>
                </div>
              )
            ) : (
              ""
            )}
            <div className="space-x-2 md:flex">
              <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                Votre rémunération totale{" "}
                {cours === "punctualMonth" ? "pour le mois" : "par séance"} :{" "}
                <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                  {datas?.cours === "regulier"
                    ? ((datas?.priceRenum * datasPunctual.hour) / 2).toFixed(2)
                    : cours === "punctualMonth"
                      ? (
                        (datasPunctualMonth?.priceRenum *
                          datasPunctualMonth.duration) /
                        2
                      ).toFixed(2)
                      : (
                        (datasPunctual?.priceRenum * datasPunctual.hour) /
                        2
                      ).toFixed(2)}{" "}
                  €
                </strong>{" "}
              </p>
            </div>
            {cours === "regulier" ? (
              datas?.days?.length === 1 ? (
                ""
              ) : (
                <div className="space-x-2 md:flex">
                  <p className="lg:text-lg md:text-base text-sm //truncate font-bold text-[#4d4d4d]">
                    Votre rémunération totale par semaine :{" "}
                    <strong className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
                      {datas.cours === "regulier"
                        ? (
                          ((datas.price * 0.9 * datas.hour) / 2) *
                          2 *
                          datas?.days?.length
                        ).toFixed(2)
                        : (
                          ((datasPunctual.price * 0.9 * datasPunctual.hour) /
                            2) *
                          2 *
                          datas?.days?.length
                        ).toFixed(2)}{" "}
                      €
                    </strong>{" "}
                  </p>
                </div>
              )
            ) : (
              ""
            )}
            <div className="mt-2 text-sm font-bold text-black lg:text-lg md:text-base">
              <p>
                Votre client sera alerté directement par mail par l'Urssaf dès
                lors que la demande de paiement lui parviendra. Il aura alors
                deux jours pour contester ou valider le paiement. Sans action de
                sa part pendant ces 48 heures, le paiement sera considéré comme
                automatiquement validé.
              </p>
            </div>
          </div>
        </div>
        <div className="absolute md:hidden flex justify-around mx-auto space-x-4 md:translate-y-44 w-full px-4">
          <div className="flex w-1/2 rounded-full bg-white text-orange  border-2 border-orange font-extrabold justify-center">
            <div
              onClick={() => handleBack()}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit()}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
            >
              {load ? "chargement.." : "Déclarer"}
            </div>
          </div>
        </div>
        <div className="md:flex px-10 bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleBack()}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center ">
            {cours === "punctualMonth" ? (
              <>
                {Object.keys(datasPunctualMonth).length === 0 ? (
                  <button
                    type="button"
                    // onClick={(e) => handleSubmit()}
                    disabled={load}
                    className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
                  >
                    chargement
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => handleSubmit()}
                    disabled={load}
                    className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
                  >
                    {load ? "chargement" : "Déclarer"}
                  </button>
                )}
              </>
            ) : (
              <>
                {Object.keys(datasPunctual).length === 0 ? (
                  <button
                    type="button"
                    // onClick={(e) => handleSubmit()}
                    disabled={load}
                    className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
                  >
                    chargement
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => handleSubmit()}
                    disabled={load}
                    className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
                  >
                    {load ? "chargement" : "Déclarer"}
                  </button>
                )}{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepConfirmation;
