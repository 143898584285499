import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import countryData from "../../Assets/file/code_pays.json";
import Functions from '../../Helpers/Functions';
import { typeVoie } from '../../Screens/Teacher/Admin/Dashboard/UtilsDashboard/FunctionsDashbord';
interface TypeVoies {
    name: string,
    code: string
}

const typeVoies: TypeVoies[] = typeVoie.sort((a, b) => a.name.localeCompare(b.name));

const SelectTypeVoie = (props: any) => {

    const [value, setValue] = useState<TypeVoies | null>()
    useEffect(() => {
        if (props.value) {
            setValue({
                name: props.value,
                code: Functions.getCodeTypeVoie(props.value)
            })
            props.callBack(props.value)
        }
    }, [])
    return (
        <div className='px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
            <label htmlFor="" className='block text-sm lg:text-lg md:text-base'>Type de voie</label>
            <Autocomplete
                value={value}
                defaultValue={props.value ? {
                    name: props.value,
                    code: Functions.getCodeTypeVoie(props.value)
                } : null}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        props.callBack(newValue.name)
                    } else {
                        props.callBack("")
                    }
                }}
                id="country-select"
                options={typeVoies}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        sx={{
                            '&': {
                                fontFamily: "nunito",
                                fontSize: "1rem",
                                padding: 0
                            },
                            '& fieldset': {
                                border: "none",
                                fontFamily: 'nunito',
                                fontSize: "1rem",
                            },
                            '& .MuiAutocomplete-inputRoot ': {
                                padding: 0,
                                fontSize: "1rem",
                            },
                            '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                padding: "2px 0",
                                fontSize: "1rem",
                            },
                        }}
                        {...params}
                        placeholder="Sélectionnez type de voie"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
    );
};

export default SelectTypeVoie;