import React, { useEffect, useState } from "react";
import { Avatar, FormControlLabel, Radio, Tooltip } from "@mui/material";
import ClientRepository from "../../../../../Repositories/ClientRepository";
import { useNavigate } from "react-router-dom";
import {
  buttonArchivage,
  buttonDelete,
  capitalizeFirstLetter,
  handleDeclare,
  profileStudent,
  showStatusTDP,
} from "../UtilsDashboard/FunctionsDashbord";
import AddressRepository from "../../../../../Repositories/AddressRepository";
import Functions from "../../../../../Helpers/Functions";
import { useDispatch } from "react-redux";
import StepperStore from "../../../../../LocalForage/StepperStore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../../Services/FirebaseService";
import moment from "moment";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Swal from "sweetalert2";

interface dataProfileModel {
  handleOpenView: () => void;
  handleClose: () => void;
  fullName: string;
  initFullName: string;
  child: any;
  isLoad2: boolean;
  firstCondition: boolean;
  isDelete2: boolean;
  openView: boolean;
  address: any;
  isTrue: boolean;
  handleRefresh: () => void;
}
interface dataDeleteModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  setIsLoad2: React.Dispatch<React.SetStateAction<boolean>>;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: any;
}
interface dataArchiveModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  navigate: any;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
}
interface btnArchiveModel {
  data: dataArchiveModel;
  load: boolean;
  firstCondition: boolean;
}
interface btnDeleteModel {
  data: dataDeleteModel;
  load: boolean;
  firstCondition: boolean;
}

interface dataStatusModel {
  child: any;
  firstCondition: boolean;
  handleRefresh: () => void;
  isLoad2: boolean;
}

interface dataDeclareModel {
  child: any;
  firstCondition: boolean;
  nextDeclareCourse: () => void;
  fullName: string;
}

interface dataDeclareModel {
  child: any;
  firstCondition: boolean;
  nextDeclareCourse: () => void;
  fullName: string;
}

function AddSutentValid(props: any) {
  const [user] = useAuthState(auth);
  const {
    isDelete2,
    handleLoadDelete2,
    child,
    handleDeleteTrue2,
    isLoad2,
    setIsLoad2,
    onValueChange,
    onValueChange2,
    onSetDataProfile,
    onSetDataArchive,
    onSetDataDelete,
    onSetDataDeclare,
    teachr
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [focus, setFocus] = useState();
  const [openView, setOpenView] = useState(false);
  const isTrue = false;
  const [address, setAddress] = useState<any>();
  const [load, setLoad] = useState(false);
  const isTeachrContact = child?.is_teachr_contact;
  const pricePerHour = child?.price_per_hour;
  const feesPerHour = child?.fees_per_hour;
  const fullName = `${capitalizeFirstLetter(
    child?.child_first_name
  )} ${child?.user_last_name.toUpperCase()}`;
  const initFullName = `${child?.child_first_name
    .charAt(0)
    .toUpperCase()}${child?.user_last_name.charAt(0).toUpperCase()}`;
  const firstCondition = !isTeachrContact && (!pricePerHour || !feesPerHour);

  useEffect(() => {
    AddressRepository.getClientAddresses(child?.client_id).then((res) => {
      setAddress(res[0]);
    });
  }, []);
  const handleOpenView = () => {
    setOpenView(true);
  };
  const handleClose = () => {
    setOpenView(false);
  };
  const handleRefresh = () => {
    setIsLoad2(true);
    ClientRepository.refreshStatusTdp(child?.client_id)
      .then((res) => {
        handleLoadDelete2();
      })
      .catch((error) => {
        setIsLoad2(false);
        dispatch({
          type: "SHOW_ERROR",
          payload: JSON.parse(error.message).translate,
        });
      });
    setIsLoad2(false);
  };
  const dataProfile: dataProfileModel = {
    handleOpenView,
    handleClose,
    fullName,
    initFullName,
    child,
    isLoad2,
    firstCondition,
    isDelete2,
    openView,
    address,
    isTrue,
    handleRefresh,
  };
  const dataDelete: btnDeleteModel = {
    data: {
      child,
      setLoad,
      handleDeleteTrue2,
      handleLoadDelete2,
      setIsLoad2,
      navigate,
      onValueChange2,
    },
    load,
    firstCondition
  };
  const dataArchive: btnArchiveModel = {
    data: {
      child,
      setLoad,
      handleDeleteTrue2,
      handleLoadDelete2,
      navigate,
      onValueChange2,
    },
    load,
    firstCondition

  };
  const dataStatus: dataStatusModel = {
    child,
    firstCondition,
    handleRefresh,
    isLoad2
  };
   const nextDeclareCourse = () => {
      Swal.fire({
        title: `<strong ><u>Déclaration d’un cours 🖍️</u></strong>`,
        html: `Vous pouvez déclarer plusieurs cours dispensés aux élèves pour le mois actuel, ou bien déclarer un cours spécifique qui a eu lieu de manière ponctuelle.
          <br/><br/>Que souhaitez-vous déclarer ?<br/>`,
        icon: "info",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showDenyButton: true,
        confirmButtonColor: "#0054A3",
        denyButtonColor: "#0054A3",
        confirmButtonText: "Cours ponctuel",
        denyButtonText: `Plusieurs cours`,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-2",
          closeButton: "red",
        },
      }).then((result) => {
        StepperStore.setItem("student" + user?.uid, {
          value: child?.child_id,
          child: child,
        });
        StepperStore.setItem(
          "description_tdp" + child?.client_id,
          child?.description_tdp
        );
        StepperStore.setItem("activeStep" + user?.uid, 2);
        if (result.isConfirmed) {
          StepperStore.setItem("cours" + user?.uid, {
            cours: "punctual",
            client: child?.client_id,
          });
           StepperStore.setItem("client" + user?.uid, {
             client: child?.client_id,
           });
          navigate("/teachr/dashboard/declare", { state: 2 });
        } else if (result.isDenied) {
          StepperStore.setItem("cours" + user?.uid, {
            cours: "punctualMonth",
            client: child?.client_id,
          });
            StepperStore.setItem("client" + user?.uid, {
              client: child?.client_id,
            });
          navigate("/teachr/dashboard/declare", { state: 2 });
        }
      });
  }
  const dataDeclare: dataDeclareModel = {
    firstCondition,
    child,
    nextDeclareCourse,
    fullName
  };
  const handleChangeValue = () => {
    onValueChange();
    onSetDataProfile(dataProfile);
    onSetDataArchive(dataArchive);
    onSetDataDelete(dataDelete);
    onSetDataDeclare(dataDeclare);
    setFocus(child?.child_id);
  };


  return (
    <>
      <div className="hidden">
        {buttonArchivage(dataArchive)}
        {buttonDelete(dataDelete)}
        {profileStudent(dataProfile)}
      </div>
      <tr
        className={`flex w-full items-center md:text-base  text-sm group/item hover:bg-slate-100 hover:rounded-xl hover:py-2`}
      >
        <td className="md:w-2/6 w-2/5 flex items-center md:p-2 p-1 text-gray-900 whitespace-nowrap /gap-x-1  truncate">
          <FormControlLabel
            onChange={() => handleChangeValue()}
            value={child?.child_id}
            key={child?.child_id}
            label=""
            // labelPlacement="start"
            className=""
            control={
              !child?.is_teachr_contact &&
                (!child?.price_per_hour || !child?.fees_per_hour) ? (
                <Radio
                  sx={{
                    color: "#ff9647",
                    "&.Mui-checked": { color: "#ff9647" },
                  }}
                />
              ) : (
                <Radio />
              )
            }
          />
          <div className="flex items-center -translate-x-6 w-full text-gray-900 whitespace-nowrap gap-x-1  font-nunito">
            <Avatar
              sx={{
                bgcolor: firstCondition ? "#ffe5d1" : "#c7e4ff",
                color: firstCondition ? "#ff9647" : "#0054A3",
                border: firstCondition ? "2px solid #ff9647" : "2px solid #0054A3",
              }}
              className="hidden md:flex w-10 h-10 font-nunito font-bold"
            >
              {initFullName}
            </Avatar>
            <div className="ps-3">
              <div className={`${firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"} text-base font-semibold`}>{fullName}</div>
              <p className={`${firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"} font-normal text-xs cursor-text text-gray-500 `}>
                {child?.mail}
              </p>
            </div>
          </div>
        </td>
        <Tooltip
          title={`Le nombre d'heures dispensées à ${fullName} durant ce mois de ${moment().format("MMMM")}.`}
          className="font-nunito cursor-auto"
        >
          <td className={`${firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"} md:w-1/6 w-1/5 md:p-2 p-1 text-center`}>
            {child?.duration === 0
              ? "0h"
              : Functions.renderDuration(child?.duration)}
          </td>
        </Tooltip>
        <Tooltip
          title={`Le montant total facturé à ${fullName} pour les heures de cours dispensées durant ce mois de ${moment().format("MMMM")}. Veuillez noter que seulement la moitié de ce montant lui a été/sera prélevé pour les cours de ${moment().format("MMMM")}.`}
          className="font-nunito cursor-auto"
        >
          <td className={`${firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"} md:w-1/6 w-1/5 md:p-2 p-1 text-center`}>
            {child?.is_teachr_contact ? child?.amount : child?.price_per_hour * (child?.duration/2)}€
          </td>
        </Tooltip>
        <td className="md:w-1/6 w-1/5 md:p-2 p-1 ">
          {showStatusTDP(dataStatus)}
        </td>
        <td className="w-1/6 md:p-2 p-1 md:flex items-center hidden cursor-pointer hover:border-2 hover:bg-slate-200 hover:rounded-full group/edit ">{teachr?.iban ? handleDeclare(dataDeclare) : <button className={`border-2 px-2 font-semibold border-first text-first rounded-full group-hover/edit:text-gray-700 group-hover/edit:border-gray-700 group-hover/edit:font-bold `} onClick={() => navigate('/teachr/dashboard/coursetype')} >Déclarer</button> }</td>
      </tr>
    </>
  );
}

export default AddSutentValid;
